import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useMessaging } from "src/contexts/messaging-context";
import { useSubOrgContext } from "src/contexts/suborg-items-context";
import Loading from "src/components/Loading";
import { useViewportHeight } from "src/helpers/viewportHeight";

function greeting() {
  const hour = new Date().getHours();

  if (hour < 12) {
    return "Good Morning";
  } else if (hour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

const DefaultWrapper = ({
  children,
  configLoaded,
  primary_color,
  secondary_color,
  org_slug,
  org_logo,
  logout,
  page,
  first_name = "",
  background = "bg-[#F1F4F5]",
  markAllRead = () => {},
}: any) => {
  const navigate = useNavigate();
  // const { unread } = useMessaging();
  const { cards, cardsLoaded, currentSuborgTitle } = useSubOrgContext();
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  useViewportHeight();

  return (
    <div
      className="bg-black flex"
      style={
        {
          "--primary-color": primary_color,
          "--secondary-color": secondary_color,
        } as any
      }
    >
      {!configLoaded && (
        <div
          className={`absolute top-0 left-0 w-full h-[var(--vh)] flex flex-col ml-auto mr-auto ${background} overflow-hidden`}
        >
          <div className="flex justify-center items-center h-[var(--vh)] bg-black z-10">
            <svg
              aria-hidden="true"
              className="w-16 h-16 text-gray-200 animate-spin fill-primary"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {/*org_slug && <Sidebar visible={showSidebar} close={() => setShowSidebar(false)} org_slug={org_slug} setQuery={overwriteQuery} /> */}
      <div className="grow shrink"></div>

      <div
        className={`w-full h-[var(--vh)] max-w-2xl flex flex-col ml-auto mr-auto ${background} relative`}
      >
        {showOrgDropdown && (
          <div className="absolute w-full h-full z-40 bg-black bg-opacity-50 flex flex-col select-none">
            <div
              className="grow"
              onClick={() => setShowOrgDropdown(false)}
            ></div>
            <div className="w-full bg-white rounded-t-3xl fly-in-bottom pb-8">
              <div className="flex justify-between p-5 pt-8 w-full">
                <p className="text-lg font-bold">Choose Category</p>
                <button onClick={() => setShowOrgDropdown(false)}>
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="#031618"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="#031618"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              {!cardsLoaded && <Loading />}
              {cardsLoaded && (
                <div className="divide-y divide-[#DEE5E7]">
                  {cards.map((card) => {
                    return (
                      <div
                        className="flex p-3 cursor-pointer hover:bg-[#DEE5E7] transition"
                        key={card.title}
                        onClick={() => {
                          navigate(`/${org_slug}/chat/${card.suborg}`);
                          navigate(0);
                        }}
                      >
                        <div className="px-5 flex flex-row items-center">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="24"
                              height="24"
                              rx="6"
                              fill="#077E8F"
                              fill-opacity="0.1"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18.6219 11.0366L18.2337 12.4855C17.7805 14.1768 17.5539 15.0224 17.0402 15.5709C16.6346 16.0039 16.1097 16.307 15.5319 16.4417C15.4597 16.4586 15.3864 16.4715 15.3112 16.4808C14.625 16.5655 13.7875 16.3411 12.2631 15.9326C10.5718 15.4794 9.72611 15.2528 9.17765 14.7391C8.74459 14.3335 8.44153 13.8086 8.3068 13.2308C8.13616 12.4989 8.36275 11.6533 8.81594 9.96197L9.20416 8.51308C9.26932 8.2699 9.32984 8.04405 9.38716 7.83421C9.72846 6.58468 9.95782 5.89724 10.3976 5.42765C10.8032 4.99459 11.3282 4.69153 11.906 4.5568C12.6378 4.38616 13.4835 4.61275 15.1748 5.06594C16.8661 5.51912 17.7118 5.74571 18.2602 6.25941C18.6933 6.66501 18.9963 7.18993 19.1311 7.76777C19.3017 8.4996 19.0751 9.34526 18.6219 11.0366ZM11.2893 10.3544C11.3697 10.0543 11.6782 9.87626 11.9782 9.95667L15.6004 10.9272C15.9005 11.0076 16.0786 11.3161 15.9982 11.6162C15.9178 11.9162 15.6094 12.0943 15.3093 12.0139L11.6871 11.0433C11.387 10.9629 11.2089 10.6545 11.2893 10.3544ZM10.7067 12.5275C10.7871 12.2274 11.0955 12.0494 11.3956 12.1298L13.5689 12.7121C13.869 12.7925 14.0471 13.1009 13.9667 13.401C13.8863 13.7011 13.5778 13.8792 13.2777 13.7988L11.1044 13.2164C10.8043 13.136 10.6263 12.8276 10.7067 12.5275Z"
                              fill="#077E8F"
                            />
                            <path
                              opacity="0.5"
                              d="M15.3112 16.4808C15.1548 16.9596 14.8799 17.3927 14.5102 17.7389C13.9618 18.2526 13.1161 18.4792 11.4248 18.9324C9.73348 19.3856 8.88783 19.6122 8.156 19.4415C7.57815 19.3068 7.05324 19.0037 6.64763 18.5707C6.13394 18.0222 5.90735 17.1766 5.45416 15.4853L5.06594 14.0364C4.61275 12.3451 4.38616 11.4994 4.5568 10.7676C4.69153 10.1897 4.99459 9.66481 5.42765 9.2592C5.97611 8.74551 6.82177 8.51892 8.51308 8.06573C8.83305 7.98 9.12277 7.90237 9.38716 7.83401C9.38718 7.83393 9.38714 7.83409 9.38716 7.83401C9.32984 8.04385 9.26932 8.26993 9.20416 8.5131L8.81594 9.96199C8.36275 11.6533 8.13616 12.499 8.3068 13.2308C8.44153 13.8086 8.74459 14.3335 9.17765 14.7392C9.72611 15.2528 10.5718 15.4794 12.2631 15.9326C13.7875 16.3411 14.6249 16.5655 15.3112 16.4808Z"
                              fill="#077E8F"
                            />
                          </svg>

                          <span className="ml-5">{card.title}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="w-full relative bg-white mb-2">
          {page === "chat" && (
            <div className="p-5 px-8 flex flex-row">
              <button
                className="mr-5"
                onClick={() => navigate(`/${org_slug}/home`)}
              >
                <svg
                  width="10"
                  height="20"
                  viewBox="0 0 8 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 8L7 15"
                    stroke="#191919"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div className="flex items-center">
                <img
                  src={"/perpetual_bot_icon.svg"}
                  alt="logo"
                  className="w-8 h-8"
                />
                <p className="font-bold ml-3">
                  {currentSuborgTitle.length > 0
                    ? currentSuborgTitle
                    : "TeamMate chat"}
                </p>
              </div>
              <div className="grow shrink"></div>
              {cards.length > 0 && (
                <button onClick={() => setShowOrgDropdown(true)}>
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.75 7C20.75 7.41421 20.4142 7.75 20 7.75L4 7.75C3.58579 7.75 3.25 7.41421 3.25 7C3.25 6.58579 3.58579 6.25 4 6.25L20 6.25C20.4142 6.25 20.75 6.58579 20.75 7Z"
                      fill="#727D80"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L4 12.75C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12Z"
                      fill="#727D80"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.75 17C20.75 17.4142 20.4142 17.75 20 17.75L4 17.75C3.58579 17.75 3.25 17.4142 3.25 17C3.25 16.5858 3.58579 16.25 4 16.25L20 16.25C20.4142 16.25 20.75 16.5858 20.75 17Z"
                      fill="#727D80"
                    />
                  </svg>
                </button>
              )}
            </div>
          )}
          {page === "home" && (
            <div className="flex flex-row px-8 border-solid border-b-2 border-b-[#DEE5E7]">
              <div className="mt-3 p-5">
                <p className="text-2xl font-bold text-left">
                  {greeting()}, {first_name}!
                </p>
                <p className="text-left text-xl mt-1">
                  How can I help you today?
                </p>
              </div>
              <div className="grow shrink"></div>
              <div className="mt-5 relative">
                <button onClick={() => setShowUserMenu(!showUserMenu)}>
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z"
                      fill="#727D80"
                    />
                    <path
                      d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
                      fill="#727D80"
                    />
                    <path
                      d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z"
                      fill="#727D80"
                    />
                  </svg>
                </button>
                {showUserMenu && (
                  <div className="absolute bg-white right-0 rounded-2xl border-solid border-2 border-[#DEE5E7] drop-shadow-xl">
                    <button
                      className="rounded-2xl hover:bg-black/50 p-4 px-8 m-1"
                      onClick={() => logout(org_slug)}
                    >
                      <div className="flex items-center">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.05487 21.75C9.42246 21.75 10.5248 21.75 11.3917 21.6335C12.2919 21.5125 13.0497 21.2536 13.6516 20.6516C14.1766 20.1267 14.4418 19.4816 14.5811 18.7236C14.7163 17.9871 14.7422 17.0857 14.7482 16.0042C14.7506 15.59 14.4166 15.2523 14.0024 15.25C13.5882 15.2477 13.2506 15.5816 13.2483 15.9958C13.2422 17.0893 13.2138 17.8644 13.1057 18.4527C13.0016 19.0195 12.8344 19.3475 12.591 19.591C12.3142 19.8678 11.9257 20.0482 11.1919 20.1469C10.4365 20.2484 9.43541 20.25 8 20.25L7 20.25C5.56458 20.25 4.56347 20.2484 3.80812 20.1468C3.07434 20.0482 2.68577 19.8678 2.40901 19.591C2.13224 19.3142 1.9518 18.9257 1.85314 18.1919C1.75159 17.4365 1.75 16.4354 1.75 15L1.75 7C1.75 5.56458 1.75159 4.56347 1.85315 3.80812C1.9518 3.07434 2.13225 2.68577 2.40901 2.40901C2.68577 2.13224 3.07434 1.9518 3.80812 1.85314C4.56347 1.75159 5.56458 1.75 7 1.75L8 1.75C9.43542 1.75 10.4365 1.75159 11.1919 1.85314C11.9257 1.9518 12.3142 2.13224 12.591 2.40901C12.8344 2.65246 13.0016 2.98053 13.1057 3.54734C13.2138 4.13559 13.2422 4.91067 13.2483 6.00417C13.2506 6.41838 13.5882 6.75229 14.0024 6.74999C14.4166 6.74768 14.7506 6.41003 14.7483 5.99582C14.7422 4.91429 14.7163 4.01291 14.5811 3.27635C14.4418 2.51835 14.1766 1.87328 13.6517 1.34835C13.0497 0.746431 12.2919 0.487537 11.3918 0.366519C10.5248 0.249959 9.42246 0.249976 8.05487 0.249997L6.94513 0.249997C5.57754 0.249976 4.47522 0.249958 3.60825 0.366518C2.70814 0.487536 1.95027 0.74643 1.34835 1.34834C0.746431 1.95026 0.487539 2.70814 0.366522 3.60824C0.249961 4.47521 0.249979 5.57754 0.249999 6.94513L0.249999 15.0549C0.249978 16.4225 0.24996 17.5248 0.36652 18.3917C0.487538 19.2919 0.74643 20.0497 1.34835 20.6516C1.95026 21.2536 2.70814 21.5125 3.60825 21.6335C4.47521 21.75 5.57753 21.75 6.94511 21.75L8.05487 21.75Z"
                            fill="#031618"
                          />
                          <path
                            d="M8.00021 11.75C7.586 11.75 7.25021 11.4142 7.25021 11C7.25021 10.5858 7.586 10.25 8.00021 10.25L18.9728 10.25L17.0121 8.56944C16.6976 8.29987 16.6612 7.8264 16.9308 7.5119C17.2003 7.19741 17.6738 7.16099 17.9883 7.43056L21.4883 10.4306C21.6545 10.573 21.7502 10.7811 21.7502 11C21.7502 11.2189 21.6545 11.427 21.4883 11.5694L17.9883 14.5694C17.6738 14.839 17.2003 14.8026 16.9308 14.4881C16.6612 14.1736 16.6976 13.7001 17.0121 13.4306L18.9728 11.75L8.00021 11.75Z"
                            fill="#031618"
                          />
                        </svg>

                        <span className="text-lg ml-2">Logout</span>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {page === "notifications" && (
            <div className="flex py-8 px-4 justify-between">
              <h1 className="font-bold text-2xl px-1">Notifications</h1>
              <button onClick={markAllRead}>
                <span className="text-primary font-bold">Mark all as read</span>
              </button>
            </div>
          )}
          {page === "notification" && (
            <div className="flex flex-row px-8 py-5 border-solid border-b-2 border-b-[#DEE5E7]">
              <button
                className="mr-5"
                onClick={() => navigate(`/${org_slug}/notifications`)}
              >
                <svg
                  width="10"
                  height="20"
                  viewBox="0 0 8 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 8L7 15"
                    stroke="#191919"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <span className="text-lg font-bold">Notifications</span>
            </div>
          )}
        </div>
        {/* <div className="bg-[#333333] w-full flex justify-around max-w-2xl items-center relative"></div> */}
        {children}
      </div>
      <div className="grow shrink"></div>
    </div>
  );
};

export default DefaultWrapper;
