export const mapIcon = (type: string) => {
  switch (type) {
    case "pdf":
      return "/icons/pdf.svg";
    case "docx":
      return "/icons/doc.svg";
    case "doc":
      return "/icons/doc.svg";
    case "pptx":
      return "/icons/ppt.svg";
    case "xlsx":
      return "/icons/doc.svg";
    case "txt":
      return "/icons/txt.svg";
    case "eps":
      return "/icons/eps.svg";
    case "jpg":
      return "/icons/jpg.svg";
    case "png":
      return "/icons/png.svg";
    default:
      return "/icons/txt.svg";
  }
};
