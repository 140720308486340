import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useApi } from "src/api/api";
import { useMessaging } from "src/contexts/messaging-context";

const Navbar = ({
  currentPage,
  org_slug,
}: {
  currentPage: string;
  org_slug: string;
}) => {
  const navigate = useNavigate();
  const api = useApi(org_slug);

  const { unread, fetchUnread } = useMessaging();

  useEffect(() => {
    if (!api) return;
    fetchUnread(api);
  }, [fetchUnread, api]);

  return (
    <div className="bg-white border-solid border-t-2 border-[#E7EAEB] p-2 px-5 flex justify-around grow-0">
      <button
        onClick={() => {
          navigate(`/${org_slug}/home`);
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <svg
            width="31"
            height="30"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.50002 12.2039C2.50002 9.91549 2.50002 8.77128 3.01922 7.82274C3.53842 6.87421 4.48696 6.28551 6.38405 5.10813L8.38405 3.86687C10.3894 2.62229 11.3921 2 12.5 2C13.608 2 14.6106 2.62229 16.616 3.86687L18.616 5.10812C20.5131 6.28551 21.4616 6.87421 21.9808 7.82274C22.5 8.77128 22.5 9.91549 22.5 12.2039V13.725C22.5 17.6258 22.5 19.5763 21.3284 20.7881C20.1569 22 18.2713 22 14.5 22H10.5C6.72878 22 4.84316 22 3.67159 20.7881C2.50002 19.5763 2.50002 17.6258 2.50002 13.725V12.2039Z"
              stroke={currentPage === "home" ? "#077E8F" : "#727D80"}
              stroke-width="1.5"
            />
            <path
              d="M12.5 15L12.5 18"
              stroke={currentPage === "home" ? "#077E8F" : "#727D80"}
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <span
            className={`${
              currentPage === "home" ? "text-[#077E8F]" : "text-[#727D80]"
            } font-semibold`}
          >
            Home
          </span>
        </div>
      </button>
      <button
        onClick={() => {
          navigate(`/${org_slug}/notifications`);
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="relative">
            <svg
              width="31"
              height="30"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.83325 22H15.8333C20.8333 22 22.8333 20 22.8333 15V9C22.8333 4 20.8333 2 15.8333 2H9.83325C4.83325 2 2.83325 4 2.83325 9V15C2.83325 20 4.83325 22 9.83325 22Z"
                stroke={currentPage === "notifications" ? "#077E8F" : "#727D80"}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.83325 13.0002H6.59325C7.35325 13.0002 8.04325 13.4302 8.38325 14.1102L9.27325 15.9002C9.83325 17.0002 10.8333 17.0002 11.0733 17.0002H14.6033C15.3633 17.0002 16.0533 16.5702 16.3933 15.8902L17.2833 14.1002C17.6233 13.4202 18.3133 12.9902 19.0733 12.9902H22.8133"
                stroke={currentPage === "notifications" ? "#077E8F" : "#727D80"}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.1732 7H14.5032"
                stroke={currentPage === "notifications" ? "#077E8F" : "#727D80"}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.3333 10H15.3333"
                stroke={currentPage === "notifications" ? "#077E8F" : "#727D80"}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {unread > 0 && (
              <span className="absolute bottom-0 left-0 ml-5 mb-4 bg-primary text-sm px-2 text-white rounded-full font-bold">
                {unread}
              </span>
            )}
          </div>

          <span
            className={`${
              currentPage === "notifications"
                ? "text-[#077E8F]"
                : "text-[#727D80]"
            } font-semibold`}
          >
            Notifications
          </span>
        </div>
      </button>
    </div>
  );
};

export default Navbar;
