import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BASE_URL } from 'src/config';
import useDocumentTitle from 'src/helpers/useDocumentTitle';
import { jwtDecode } from 'jwt-decode';
import { checkUserStatus } from 'src/api_/auth';
import './Chat.css';

const Chat = () => {
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  let { org_slug = "reverb" } = useParams();
  const navigate = useNavigate();
  const [botConfig, setBotConfig] = useState(null);
  const [messages, setMessages] = useState([]);
  const [retryCount, setRetryCount] = useState(0);
  const [eventSource, setEventSource] = useState(null);

  useDocumentTitle(botConfig ? botConfig.page_title : 'Chat');

  useEffect(() => {
    if (org_slug !== localStorage.getItem('sessionSlug')) {
      navigate(`/${org_slug}/chat2`);
    }
  }, [navigate, org_slug]);

  useEffect(() => {
    const storedBotConfig = localStorage.getItem(`${org_slug}_botConfig`);
    if (storedBotConfig) {
      const parsedBotConfig = JSON.parse(storedBotConfig);
      console.log(`[${org_slug}] botConfig retrieved from local storage:`, parsedBotConfig);
      setBotConfig(parsedBotConfig);
      setMessages([
        {
          text: parsedBotConfig.bot_greeting,
          inbound: true,
        },
      ]);
    } else {
      console.log(`[${org_slug}] botConfig not found in local storage`);
      setMessages([
        {
          text: 'Hello! How can I help you today?',
          inbound: true,
        },
      ]);
    }
  }, [org_slug]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleInput = (e) => {
    setNewMessage(e.target.innerText);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getDocumentUrl = useCallback(async (filename) => {
    try {
      const response = await fetch(`${BASE_URL}/${org_slug}/rag/get-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(`${org_slug}_sessionToken`)}`,
        },
        body: JSON.stringify({ filename }),
      });
  
      if (response.ok) {
        const { url } = await response.json();
        window.open(url, '_blank');
      } else {
        console.error('Failed to get temporary document URL');
      }
    } catch (error) {
      console.error('Error getting temporary document URL:', error);
    }
  }, [org_slug]);
  
  const formatMessage = useCallback((text) => {
    const [mainContent, documentSources] = text.split('<br><br>Available Documents:<br>');
    let formattedText = mainContent
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      .replace(/\*(.*?)\*/g, '<i>$1</i>')
      .replace(/__(.*?)__/g, '<u>$1</u>')
      .replace(/`(.*?)`/g, '<code>$1</code>');
  
    // Extract and format unique links
    const linksSection = formattedText.split('<br><br>Available Links:<br>');
    let linksText = "";
    if (linksSection.length > 1) {
      const links = linksSection[1].split('<br>');
      const uniqueLinks = [...new Set(links.map(link => link.trim()))]
        .map(link => {
          // Remove leading asterisk, whitespace, and any <i> tags
          return link.replace(/^\*\s*/, '').replace(/<\/?i>/g, '').trim();
        })
        // Remove duplicates (case-insensitive) and always use lowercase
        .reduce((acc, link) => {
          const lowercaseLink = link.toLowerCase();
          if (!acc.some(l => l.toLowerCase() === lowercaseLink)) {
            acc.push(lowercaseLink);
          }
          return acc;
        }, [])
        // Sort alphabetically (case-insensitive)
        .sort((a, b) => a.localeCompare(b));
  
      linksText = uniqueLinks
        .map(link => `<li><a href="${link}" style="color:blue;text-decoration:underline;" target="_blank">${link}</a></li>`)
        .join('');
      if (linksText) {
        linksText = `<br><br><b>More Information:</b><ul>${linksText}</ul>`;
      }
    }
  
    // Add links section before the document sources
    formattedText = linksSection[0] + linksText;
  
    // Format and append document sources if present
    if (documentSources) {
      const sources = documentSources.split('<br>');
      const formattedSources = sources
        .filter(source => source.trim())
        .map(source => {
          const cleanSource = source.replace(/^\*\s*/, '');
          
          // Extract file extension
          const fileExtension = cleanSource.split('.').pop().toLowerCase();
          
          // Determine icon URL based on file extension
          let iconUrl;
          switch (fileExtension) {
            case 'pdf':
              iconUrl = "https://sopai-images-dev.s3.us-east-2.amazonaws.com/PDF_file_icon.png";
              break;
            case 'docx':
              iconUrl = "https://sopai-images-dev.s3.us-east-2.amazonaws.com/WORD_file_icon.png";
              break;
            case 'pptx':
              iconUrl = "https://sopai-images-dev.s3.us-east-2.amazonaws.com/POWERPOINT_file_icon.png";
              break;
            case 'xlsx':
              iconUrl = "https://sopai-images-dev.s3.us-east-2.amazonaws.com/EXCEL_file_icon.png";
              break;
            case 'txt':
              iconUrl = "https://sopai-images-dev.s3.us-east-2.amazonaws.com/TXT_file_icon.png";
              break;
            default:
              iconUrl = "https://sopai-images-dev.s3.us-east-2.amazonaws.com/DEFAULT_file_icon.png";
          }
  
          return `
            <div class="document-card">
              <img src="${iconUrl}" alt="${fileExtension} icon">
              <div class="document-name">${cleanSource}</div>
              <a href="#" onclick="window.getDocumentUrl('${cleanSource}'); return false;">Open</a>
            </div>`;
        })
        .join('');
        
      if (formattedSources) {
        formattedText += `<br><br><b>Source Documents:</b><div class="document-cards-container">${formattedSources}</div>`;
      }
    }
  
    return formattedText;
  }, []);
  
  
  
  
  useEffect(() => {
    window.getDocumentUrl = getDocumentUrl;
    return () => {
      delete window.getDocumentUrl;
    };
  }, [getDocumentUrl]);
  
  // For debugging
  const logMessageProcessing = (message) => {
    console.log('Raw message text:', message);
    const formattedMessage = formatMessage(message);
    console.log('Formatted message:', formattedMessage);
    return formattedMessage;
  };

  const loginWithPassphrase = useCallback(async () => {
    console.log(`[${org_slug}] Attempting to login with stored passphrase`);
    try {
      const passphrase = localStorage.getItem(`${org_slug}_passphrase`);
      if (!passphrase) {
        console.log(`[${org_slug}] Passphrase not found in localStorage`);
        navigate(`/${org_slug}/login`);
        return false;
      }

      const response = await fetch(`${BASE_URL}/${org_slug}/auth/login-with-passphrase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ passphrase, org_slug })
      });

      if (!response.ok) {
        console.log(`[${org_slug}] Passphrase login request failed with status: ${response.status}`);
        navigate(`/${org_slug}/login`);
        return false;
      }

      const data = await response.json();
      if (data.access_token) {
        localStorage.setItem(`${org_slug}_sessionToken`, data.access_token);
        localStorage.setItem(`${org_slug}_userId`, data.user_id);
        console.log(`[${org_slug}] Successfully logged in with passphrase. New session token stored.`);
        return true;
      }
    } catch (loginError) {
      console.error(`[${org_slug}] Error during passphrase login:`, loginError);
      navigate(`/${org_slug}/login`);
      return false;
    }
  }, [org_slug, navigate]);

  const handleTokenExpiration = useCallback(() => {
    console.log(`[${org_slug}] Handling token expiration. Logging out.`);
    localStorage.removeItem(`${org_slug}_sessionToken`);
    localStorage.removeItem(`${org_slug}_userId`);
    localStorage.removeItem(`${org_slug}_passphrase`);
    navigate(`/${org_slug}/login`);
  }, [org_slug, navigate]);

  const handleUnknownError = useCallback(async () => {
    console.log(`[${org_slug}] Handling unknown error. Attempting to re-login.`);
    if (retryCount < 100) {
      const success = await loginWithPassphrase();
      if (success) {
        setRetryCount(0); // Reset retry count on success
      } else {
        setRetryCount((prevCount) => prevCount + 1);
      }
    } else {
      console.log(`[${org_slug}] Max retry attempts reached. Logging out.`);
      handleTokenExpiration();
    }
  }, [loginWithPassphrase, org_slug, retryCount, handleTokenExpiration]);

  const handleInactiveAccount = useCallback(() => {
    console.log(`[${org_slug}] Handling inactive account. Logging out and redirecting to login.`);
    localStorage.removeItem(`${org_slug}_sessionToken`);
    localStorage.removeItem(`${org_slug}_userId`);
    localStorage.removeItem(`${org_slug}_passphrase`);
    navigate(`/${org_slug}/login`);
  }, [org_slug, navigate]);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const userId = localStorage.getItem(`${org_slug}_userId`);
        const passphrase = localStorage.getItem(`${org_slug}_passphrase`);
        const token = localStorage.getItem(`${org_slug}_sessionToken`);

        console.log(`[${org_slug}] Checking user status. User ID: ${userId}, Token: ${token ? 'present' : 'not found'}`);

        if (token) {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          const timeLeft = decodedToken.exp - currentTime;
          const timeLeftMinutes = timeLeft / 60;
          console.log(`[${org_slug}] Token will expire in: ${timeLeft} seconds (${timeLeftMinutes.toFixed(2)} minutes)`);

          if (timeLeft <= 60) { // Logout a minute before the token expires
            console.log(`[${org_slug}] Token has expired or will expire soon`);
            handleTokenExpiration();
            return;
          }
        }

        const response = await checkUserStatus({ userId, passphrase, orgSlug: org_slug });
        if (response.status !== 'active') {
          console.log(`[${org_slug}] User account is inactive or expired`);
          handleInactiveAccount();
        } else {
          console.log(`[${org_slug}] User status is active`);
          setBotConfig(response.bot_config);
        }
      } catch (error) {
        console.error(`[${org_slug}] Error checking user status:`, error);
        if (error.message !== 'User account not active or expired') {
          handleUnknownError();
        } else {
          handleInactiveAccount();
        }
      }
    };

    checkStatus();
  }, [org_slug, navigate, handleTokenExpiration, handleUnknownError, handleInactiveAccount]);

  const logout = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      console.log(`[${org_slug}] User confirmed logout`);
      localStorage.removeItem(`${org_slug}_sessionToken`);
      localStorage.removeItem(`${org_slug}_userId`);
      localStorage.removeItem(`${org_slug}_passphrase`);
      navigate(`/${org_slug}/login`);
    }
  };

  const sendMessage = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!newMessage.trim()) return;
    messageInputRef.current.innerHTML = '';

    const sendAndHandleMessage = async (message) => {
      const sentMessage = { text: message, inbound: false };
      setMessages([...messages, sentMessage]);

      // Close the previous event source if it exists
      if (eventSource) {
        eventSource.close();
        setEventSource(null);
      }

      // Add the processing message
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: botConfig ? botConfig.bot_typing : 'Bot is thinking...', inbound: true, isProcessing: true },
      ]);

      try {
        const token = localStorage.getItem(`${org_slug}_sessionToken`);
        if (!token) {
          console.log(`[${org_slug}] No authentication token found. Redirecting to login.`);
          navigate(`/${org_slug}/login`);
          return;
        }

        const response = await fetch(`${BASE_URL}/${org_slug}/rag/sse-query`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ prompt: message }),
        });

        console.log(`[${org_slug}] Sent query to server. Waiting for response...`);

        if (response.ok) {
          const { stream_url } = await response.json();
          console.log(`[${org_slug}] Received stream_url: ${stream_url}`);
          const newEventSource = new EventSource(`${BASE_URL}${stream_url}`);
          let streamedMessage = { text: '', inbound: true };

          newEventSource.onmessage = (event) => {
            //console.log("Received message:", event.data);
            if (event.data === '[DONE]') {
              console.log("Received [DONE] message");
              newEventSource.close();
            } else if (event.data.startsWith('[ERROR]')) {
              console.error('Error:', event.data.slice(7));
              newEventSource.close();
            } else {
              streamedMessage = { ...streamedMessage, text: streamedMessage.text + event.data };
              setMessages((prevMessages) => {
                const lastMessage = prevMessages[prevMessages.length - 1];
                if (lastMessage && lastMessage.inbound) {
                  return [...prevMessages.slice(0, -1), streamedMessage];
                } else {
                  return [...prevMessages, streamedMessage];
                }
              });
            }
          };

          newEventSource.onerror = (error) => {
            console.error('Error receiving SSE:', error);
            newEventSource.close();
          };

          setEventSource(newEventSource);
        } else if (response.status === 401) {
          console.log(`[${org_slug}] Unauthorized. Redirecting to login.`);
          navigate(`/${org_slug}/login`);
        } else {
          console.log(`[${org_slug}] Failed to get stream_url, response not OK. Status: ${response.status}`);
        }
      } catch (error) {
        console.error(`[${org_slug}] Error sending message:`, error);
        handleUnknownError();
      }
    };

    try {
      const userId = localStorage.getItem(`${org_slug}_userId`);
      const passphrase = localStorage.getItem(`${org_slug}_passphrase`);
      const token = localStorage.getItem(`${org_slug}_sessionToken`);

      console.log(`[${org_slug}] User ID retrieved from local storage: ${userId}`);
      console.log(`[${org_slug}] Checking token validity`);

      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const timeLeft = decodedToken.exp - currentTime;
        const timeLeftMinutes = timeLeft / 60;
        console.log(`Token will expire in: ${timeLeft} seconds (${timeLeftMinutes.toFixed(2)} minutes)`);

        if (timeLeft <= 0) {
          console.log("LOGGED OUT. REASON: token expired");
          handleTokenExpiration();
          return;
        }
      }

      const response = await checkUserStatus({ userId, passphrase, orgSlug: org_slug });
      if (response.status !== 'active') {
        console.log(`LOGGED OUT. REASON: user account is inactive or expired`);
        handleInactiveAccount();
        return;
      }

      await sendAndHandleMessage(newMessage);
    } catch (error) {
      console.error(`[${org_slug}] Error checking user status:`, error);
      if (error.message !== 'User account not active or expired') {
        handleUnknownError();
      } else {
        handleInactiveAccount();
      }
    }

    setNewMessage('');
  };

  useEffect(() => {
    return () => {
      if (eventSource) {
        eventSource.close();
        setEventSource(null);
      }
    };
  }, [eventSource]);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="logout" onClick={logout}>
          {botConfig ? botConfig.chat_title.toUpperCase() : 'Chat'}
        </div>
      </div>
      <div className="messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.inbound ? 'inbound' : 'outbound'}`}>
            {message.isProcessing ? (
              <span className="blinking">{message.text}</span>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: logMessageProcessing(message.text) }} />
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-bar">
        <div
          ref={messageInputRef}
          className="input-text"
          contentEditable
          onInput={handleInput}
          onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage(e)}
          onFocus={scrollToBottom}
          data-placeholder="Ask your question"
        ></div>
        <button className="send-button" onClick={sendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;