// src/config.js
//TEST DEV
// Read environment variable and provide a default value for development
// force change for commit
export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";
export const OPENAI_REALTIME_RELAY_ENDPOINT =
  process.env.REACT_APP_OPENAI_RELAY_DEV || "http://localhost:8001";
// "http://dev.relay.api.perpetual-teammate.com";
// export const BASE_URL =
//   process.env.REACT_APP_API_BASE_URL ||
//   "http://sopai--Publi-QtU7yoYxmybl-110685233.us-east-2.elb.amazonaws.com";

export const BOT_CONFIG = {
  reverb: {
    pageTitle: "EVE Chat",
    botName: "EVE",
    chatTitle: "EVE",
    botGreeting: "Hi there, I'm EVE - how can I help you?",
    botTyping: "EVE is typing...",
    logo: "/eve_logo_alpha.png",
    botIcon: "",
  },
  unity: {
    pageTitle: "Unity Chat",
    botName: "Bot",
    chatTitle: "Unity",
    botGreeting: "Hello! How can I help you today?",
    botTyping: "Bot is typing...",
    logo: "/unity_logo.png",
    botIcon: "",
  },
  demo: {
    pageTitle: "Demo Chat",
    botName: "Bot",
    chatTitle: "DEMO CHAT",
    botGreeting: "Hello! How may I be of service today?",
    botTyping: "Bot is typing...",
    logo: "/unity_logo.png",
    botIcon: "",
  },
  larstestorg: {
    pageTitle: "Lars Chat",
    botName: "Bot",
    chatTitle: "Lars CHAT",
    botGreeting: "Hello! How may I be of service today?",
    botTyping: "Bot is typing...",
    logo: "/unity_logo.png",
    botIcon: "",
  },
  regency: {
    pageTitle: "Regency Chat",
    botName: "Bot",
    chatTitle: "Regency",
    botGreeting: "Hello! How can I help you today?",
    botTyping: "Bot is typing...",
    logo: "/regency_logo.png",
    botIcon: "",
  },
  reverbatlanta: {
    pageTitle: "Reverb Atlanta Chat",
    botName: "Bot",
    chatTitle: "EVE - Reverb Atlanta",
    botGreeting: "Hello! How can I help you today?",
    botTyping: "Bot is typing...",
    logo: "/eve_logo_alpha.png",
    botIcon: "",
  },
  reverbhotelhamburg: {
    pageTitle: "EVE Chat",
    botName: "EVE",
    chatTitle: "EVE - Reverb Hamburg",
    botGreeting: "Hi there, I'm EVE - how can I help you?",
    botTyping: "EVE is typing...",
    logo: "/eve_logo_alpha.png",
    botIcon: "",
  },
};
