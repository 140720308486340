// auth.js
import { BASE_URL } from 'src/config';

export const checkUserStatus = async (input) => {
  let requestBody = {};
  if (input.userId && input.passphrase && input.orgSlug) {
    // Passphrase case
    requestBody = {
      user_id: input.userId,
      passphrase: input.passphrase,
      org_slug: input.orgSlug,
    };
    console.log(`[${input.orgSlug}] Request body for checkUserStatus:`, requestBody);
  } else {
    // Other cases (e.g., demo user) are pending implementation
    return { status: 'active' };
  }

  const response = await fetch(`${BASE_URL}/${input.orgSlug}/auth/check_user_status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error('User not found');
    } else if (response.status === 403) {
      throw new Error('User account not active or expired');
    } else {
      throw new Error('Failed to check user status');
    }
  }

  return await response.json();
};