import { loginWithPassphrase } from "src/api/auth";

// Attempt to login with the passphrase stored in local storage
export const attemptPassphraseLogin = async (org_slug: string) => {
    const passphrase = localStorage.getItem(`${org_slug}_passphrase`);

    if (!passphrase) {
        throw new Error('Passphrase not found');
    }

    const attempt = await loginWithPassphrase(org_slug, passphrase);

    if (attempt) {
        return attempt;
    }

    throw new Error('Failed to login with passphrase');
}

