import { AxiosInstance } from "axios";

export const getGroups = async (api: AxiosInstance, org_slug: string) => {
  try {
    const response = await api.get(`messaging/my-groups`);

    return response.data;
  } catch (error) {
    console.error("Error getting groups:", error);
    throw error;
  }
};

export const getMessages = async (
  api: AxiosInstance,
  org_slug: string,
  group_id: string
) => {
  try {
    const response = await api.get(`messaging/group/${group_id}/my-messages`);

    return response.data;
  } catch (error) {
    console.error("Error getting messages:", error);
    throw error;
  }
};

export const getMessage = async (api: AxiosInstance, post_id: string) => {
  try {
    const response = await api.get(`messaging/message/${post_id}`);

    return response.data;
  } catch (error) {
    console.log(`Error getting message ${post_id}`);
    throw error;
  }
};

export const markViewed = async (api: AxiosInstance, posts: string[]) => {
  try {
    const response = await api.post(
      `messaging/viewed-posts`,
      {
        posts,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error marking as viewed:", error);
    throw error;
  }
};

export const getTotalUnread = async (api: AxiosInstance) => {
  try {
    const response = await api.get(`messaging/total-unread`);

    return response.data;
  } catch (error) {
    console.error("Error getting unread messages:", error);
    throw error;
  }
};

export const getAllMessages = async (api: AxiosInstance) => {
  try {
    const response = await api.get(`messaging/all-notifications`);

    return response.data;
  } catch (error) {
    console.error("Error getting all messages:", error);
    throw error;
  }
};

export const markAllRead = async (api: AxiosInstance) => {
  try {
    const response = await api.post(`messaging/mark-all-read`);

    return response.data;
  } catch (error) {
    console.error("Error marking all as read:", error);
    throw error;
  }
};
