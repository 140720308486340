import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BASE_URL } from 'src/config';
import './Passphrase.css';

const Passphrase = () => {
  const [passphrase, setPassphrase] = useState('');
  const [alert, setAlert] = useState('');
  const [botConfig, setBotConfig] = useState(null);

  const navigate = useNavigate();
  let { org_slug = "reverb" } = useParams();

  // Highlight: Fetch botConfig from /method endpoint
  useEffect(() => {
    const fetchBotConfig = async () => {
      try {
        const response = await fetch(`${BASE_URL}/${org_slug}/auth/method`);
        if (response.ok) {
          const data = await response.json();
          setBotConfig(data.bot_config);
        } else {
          console.error('Failed to fetch bot configuration');
        }
      } catch (error) {
        console.error('Error fetching bot configuration:', error);
      }
    };

    fetchBotConfig();
  }, [org_slug]);

  const handlePassphraseVerification = useCallback(async (e, storedPassphrase = null) => {
    if (e) {
      e.preventDefault();
    }
    const passphraseToUse = storedPassphrase || passphrase.toLowerCase();
    console.log(`[${org_slug}] Passphrase verification initiated`);
    try {
      const response = await fetch(`${BASE_URL}/${org_slug}/auth/login-with-passphrase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ passphrase: passphraseToUse, org_slug: org_slug }),
      });
      console.log(`[${org_slug}] Passphrase verification request sent`);
      if (!response.ok) {
        console.log(`[${org_slug}] Passphrase verification failed`);
        const errorData = await response.json();
        console.log(`[${org_slug}] Error:`, errorData.detail);
        setAlert('Invalid passphrase. Please try again.');
        return;
      }
      const data = await response.json();
      if (data.access_token) {
        localStorage.setItem(`${org_slug}_sessionToken`, data.access_token);
        localStorage.setItem(`${org_slug}_userId`, data.user_id);
        localStorage.setItem(`${org_slug}_passphrase`, passphraseToUse);
        localStorage.setItem(`${org_slug}_botConfig`, JSON.stringify(data.bot_config));
        navigate(`/${org_slug}/chat`);
        console.log(`[${org_slug}] JWT received and user logged in.`);
      }
    } catch (error) {
      console.error(`[${org_slug}] Passphrase verification error:`, error);
      setAlert('An error occurred. Please try again.');
    }
  }, [org_slug, passphrase, navigate]);

  useEffect(() => {
    const storedPassphrase = localStorage.getItem(`${org_slug}_passphrase`);
    if (storedPassphrase) {
      handlePassphraseVerification(null, storedPassphrase);
    }
  }, [org_slug, handlePassphraseVerification]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* Highlight: Conditionally render logo image */}
        {botConfig && botConfig.logo ? (
          <img className="mx-auto" src={botConfig.logo} alt={botConfig.bot_name} />
        ) : (
          <img className="mx-auto" src="/eve_logo.png" alt="EVE" />
        )}
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
        <h1 className="font-semibold text-center">Please enter your passphrase</h1>
        <div className="bg-white/5 px-6 py-4 sm:rounded-lg sm:px-12">
          {alert && <div className="my-4 bg-red-400 p-2 rounded-md text-white block w-full">{alert}</div>}
          <form className='space-y-6' onSubmit={handlePassphraseVerification} method='POST'>
            <input type="text" value={passphrase} onChange={(e) => setPassphrase(e.target.value)} className="block w-full rounded-md border-1 bg-white/5 py-3 text-lg text-center text-black ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-eve-teal" placeholder="3 words separated by periods" style={{ fontSize: '1.25rem', width: '480px' }} />
            <button type="submit" className="flex w-full justify-center rounded-md bg-eve-teal px-3 py-1.5 text-sm font-semibold leading-6 text-black hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-eve-teal transition ease-in-out duration-150">
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Passphrase;