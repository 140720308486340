import React, { createContext, useContext, useState, useEffect } from "react";
import { useApi } from "src/api/api";
import { useOrgContext } from "./org-context";

interface Card {
  title: string;
  image: string;
  organization: string;
  suborg: string;
  description: string;
}

const SubOrgContext = createContext({
  cards: [] as Card[],
  setCards: (cards: Card[]) => {},
  cardsLoaded: false,
  setCardsLoaded: (loaded: boolean) => {},
  currentSuborgTitle: "",
  setCurrentSuborgTitle: (title: string) => {},
});

export default SubOrgContext;

export const useSubOrgContext = () => useContext(SubOrgContext);

const SubOrgContextUpdater = ({ org_slug }: { org_slug: string }) => {
  const api = useApi(org_slug);
  const { setCards, setCardsLoaded } = useSubOrgContext();

  useEffect(() => {
    console.log("suborg loading");
    if (window.location.pathname.includes("/login")) {
      return;
    }

    api
      .get(`/org/${org_slug}/today-items`)
      .then((data: any) => {
        setCards(data.data.items);
        setCardsLoaded(true);
      })
      .catch((error: any) => {
        console.error(error);
        setCardsLoaded(true);
      });
  }, [api, org_slug, setCards, setCardsLoaded]);

  return null;
};

export const SubOrgProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [cards, setCards] = useState<Card[]>([]);
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [currentSuborgTitle, setCurrentSuborgTitle] = useState("");
  const { org_slug } = useOrgContext();

  return (
    <SubOrgContext.Provider
      value={{
        cards,
        setCards,
        cardsLoaded,
        setCardsLoaded,
        currentSuborgTitle,
        setCurrentSuborgTitle,
      }}
    >
      {org_slug && !window.location.pathname.includes("/login") && (
        <SubOrgContextUpdater org_slug={org_slug} />
      )}
      {children}
    </SubOrgContext.Provider>
  );
};
