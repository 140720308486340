import axios from "axios";
import { BASE_URL } from "src/config";

// Fetch the org's config
export const fetchOrgConfig = async (org_slug: string, auth: boolean) => {
  const response = await axios.get(
    `${BASE_URL}/org/${org_slug}/config${auth ? "/authed" : ""}`,
    {
      headers: {
        "Content-Type": "application/json",
        ...(auth
          ? {
              Authorization: `Bearer ${localStorage.getItem(
                `${org_slug}_sessionToken`
              )}`,
            }
          : {}),
      },
    }
  );

  if (response.status !== 200) {
    return null;
  }

  if (response.data && response.data.config) {
    return response.data;
  }
};
