import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BASE_URL, BOT_CONFIG } from 'src/config';
// Import your CSS file as needed

const Verify = () => {
  const [code, setCode] = useState('');
  const [alert, setAlert] = useState('');
  const navigate = useNavigate();
  let { org_slug = "reverb" } = useParams(); // Default org_slug to "reverb"

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      setAlert('');
      const sessionPhone = localStorage.getItem(`${org_slug}_sessionPhone`); // Changed to localStorage

      const response = await fetch(`${BASE_URL}/${org_slug}/auth/jwt-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: sessionPhone, verification_code: code })
      });

      if (!response.ok) {
        setAlert('Invalid code. Please try again.');
        return;
      }

      const data = await response.json();

      if (data.access_token !== '') { // Assuming access_token key in the response
        localStorage.setItem(`${org_slug}_sessionToken`, data.access_token); // Changed to localStorage
        navigate(`/${org_slug}/chat`);
      }
      
    } catch (error) {
      console.error('Verification failed:', error);
    }

  };

  const logo = BOT_CONFIG[org_slug] ? BOT_CONFIG[org_slug].logo : '/eve_logo.png';
  const botName = BOT_CONFIG[org_slug] ? BOT_CONFIG[org_slug].botName : 'EVE';

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto"
          src={logo}
          alt={botName}
        />
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        {/* Conditional message change based on organization slug */}
        {org_slug === "reverbatlanta" ? (
          <h1 className="font-semibold text-center">Please enter the passphrase below:</h1>
        ) : (
          <h1 className="font-semibold text-center">You will receive an SMS message with a confirmation code shortly.</h1>
        )}
        <div className="bg-white/5 px-6 py-4 sm:rounded-lg sm:px-12">
        {alert && <div className="my-4 bg-red-400 p-2 rounded-md text-white block w-full">{alert}</div>}
          <form className="space-y-6" onSubmit={handleVerification} method="POST">
            <div>
              <div className="mt-2">
                <input
                  id="username"
                  name="phone-number"
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="block w-full rounded-md border-1 bg-white/5 py-3 text-lg text-center text-black ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-eve-teal"
                  placeholder="000000"
                  style={{ fontSize: '1.25rem' }} // Larger font size
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-eve-teal px-3 py-1.5 text-sm font-semibold leading-6 text-black hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-eve-teal transition ease-in-out duration-150"
              >
                Sign in
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};

export default Verify;