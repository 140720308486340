import axios from 'axios';
import { BASE_URL } from 'src/config';
import { checkUserStatus, clearSessionData, getSessionToken, isTokenValid } from './auth';
import { useMemo } from 'react';

const invalidateAuth = (org_slug: string) => {
    clearSessionData(org_slug);
    window.location.href = `/${org_slug}/login`;
}

export const useApi = (org_slug: string) => {
    return useMemo(() => {
        const api = axios.create({
            baseURL: `${BASE_URL}`,
        });
        
        api.interceptors.request.use(async (config: any) => {
            try {
                const token = await getSessionToken(org_slug);
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            } catch (error) {
                console.error('Error setting up request:', error);

                // Redirect to login page
                clearSessionData(org_slug);
                window.location.href = `/${org_slug}/login`;
            }
        
            return config;
        });
        
        api.interceptors.response.use(
            (response: any) => response,
            (error: any) => {
            if (error.response?.status === 401) {
                console.log('Unauthorized access, redirecting to login');
                invalidateAuth(org_slug);
            }

            // Check if token is valid
            const valid = isTokenValid(org_slug);
            if (!valid) {
                console.log('Invalid token, redirecting to login');
                invalidateAuth(org_slug);
            }

            // Check if the user is valid, only if passphrase is available to us
            const passphrase = localStorage.getItem(`${org_slug}_passphrase`);
            const userId = localStorage.getItem(`${org_slug}_userId`);
            if (passphrase) {
                console.log('Checking user status');
                checkUserStatus({ userId: userId, passphrase: passphrase, orgSlug: org_slug }).then((response) => {
                    console.log('User status:', response);
                    if(response.status !== 'active') {
                        console.log('User account not active or expired');
                        invalidateAuth(org_slug);
                    }
                }).catch((error) => {
                    console.error('Error checking user status:', error);
                    invalidateAuth(org_slug);
                });
            }
        
            return Promise.reject(error);
            }
        );
        
        return api;
    }, [org_slug]);
}