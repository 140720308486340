import React, { useState, useEffect } from "react";
import useDocumentTitle from "src/helpers/useDocumentTitle";
import { useNavigate, useParams } from "react-router";
import { useConfigFromApi } from "src/helpers/load-org-from-api";
import { useOrgContext } from "src/contexts/org-context";
import DefaultWrapper from "src/components/DefaultWrapper";
import { useApi } from "src/api/api";
// @ts-ignore
import { getMessage, markViewed } from "src/api/messaging";
import { NotificationPost } from "./Notifications";
import Loading from "src/components/Loading";
import YozoraParser from "@yozora/parser";
import Markdown, { MarkdownProvider } from "@yozora/react-markdown";
import { calcDefinitionMap } from "@yozora/ast-util";
import { useMessaging } from "src/contexts/messaging-context";

const Notification = () => {
  const { org_slug = "", post_id = "" } = useParams();
  const { configLoaded } = useConfigFromApi(org_slug, true);
  const { org_logo, pageTitle, primary_color, secondary_color, first_name } =
    useOrgContext();
  const { unread, setUnread } = useMessaging();
  useDocumentTitle(pageTitle || "Notification");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  // @ts-ignore
  const [title, setTitle] = useState("");
  // @ts-ignore
  const [content, setContent] = useState("");
  const api = useApi(org_slug);

  const parser = new YozoraParser();

  useEffect(() => {
    getMessage(api, post_id)
      .then((data: NotificationPost) => {
        console.log(data);
        setContent(data.content);
        setTitle(data.title);
        setLoading(false);
      })
      .catch((error: any) => {
        setError(error);
      });
  }, [api, post_id]);

  const markRead = () => {
    setLoading(true);
    markViewed(api, [post_id])
      .then(() => {
        setUnread(unread - 1);
        setLoading(false);
        navigate(`/${org_slug}/notifications`);
      })
      .catch((error: any) => {
        setError(error);
      });
  };

  const ast = parser.parse(content, {});
  const definitionMap = calcDefinitionMap(ast);

  return (
    <DefaultWrapper
      configLoaded={configLoaded}
      org_slug={org_slug}
      page="notification"
      org_logo={org_logo}
      primary_color={primary_color}
      secondary_color={secondary_color}
      first_name={first_name}
      background="bg-white"
    >
      {loading && <Loading />}
      {error && <div>{/* <span>{error}</span> */}</div>}
      <div className="p-5 flex flex-col overflow-y-auto">
        <span className="font-bold text-2xl text-left py-2">{title}</span>
        <div className="text-left text-lg overflow-y-auto flex">
          <MarkdownProvider definitionMap={definitionMap as any}>
            <Markdown ast={ast} />
          </MarkdownProvider>
        </div>
      </div>
      <div className="grow shrink"></div>
      <div className="w-full p-5 py-8">
        <button
          className="w-full"
          onClick={() => {
            markRead();
          }}
        >
          <div className="py-4 bg-primary rounded-2xl">
            <span className="font-bold text-2xl text-white">Got It</span>
          </div>
        </button>
      </div>
    </DefaultWrapper>
  );
};

export default Notification;
