import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getAllMessages, markAllRead } from "src/api/messaging";
import DefaultWrapper from "src/components/DefaultWrapper";
import Navbar from "src/components/Navbar";
import { useOrgContext } from "src/contexts/org-context";
import { useConfigFromApi } from "src/helpers/load-org-from-api";
import useDocumentTitle from "src/helpers/useDocumentTitle";
import { useApi } from "src/api/api";
import Loading from "src/components/Loading";
import NotificationsBlock from "src/components/NotificationsBlock";
import { useMessaging } from "src/contexts/messaging-context";

export interface NotificationPost {
  id: string;
  content: string;
  organization_id: string;
  poster_id: string;
  poster_type: string;
  inserted_at: string;
  updated_at: string;
  title: string;
}

export interface NotificationItem {
  post: NotificationPost;
  is_viewed: boolean;
}

interface NotificationPayload {
  unread: NotificationItem[];
  today: NotificationItem[];
  yesterday: NotificationItem[];
  last_7_days: NotificationItem[];
  past_month: NotificationItem[];
  older: NotificationItem[];
}

const Notifications = () => {
  const { org_slug = "" } = useParams();
  const { configLoaded } = useConfigFromApi(org_slug, true);
  const { org_logo, pageTitle, primary_color, secondary_color, first_name } =
    useOrgContext();
  const { setUnread } = useMessaging();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [items, setItems] = useState<NotificationPayload>({
    unread: [],
    today: [],
    yesterday: [],
    last_7_days: [],
    past_month: [],
    older: [],
  });
  const api = useApi(org_slug);
  useDocumentTitle(pageTitle || "Notifications");

  // Calculate the sum of the length of all entries
  const totalItems = () => {
    return (
      items.unread.length +
      items.today.length +
      items.yesterday.length +
      items.last_7_days.length +
      items.past_month.length +
      items.older.length
    );
  };

  // const markAllRead = () => {
  //   const unreadIds = items.unread.map((item) => item.post.id);
  //   api.messaging.markAllRead(unreadIds).then(() => {
  //     setItems({
  //       unread: [],
  //       today: items.today.concat(items.unread),
  //       yesterday: items.yesterday,
  //       last_7_days: items.last_7_days,
  //       past_month: items.past_month,
  //       older: items.older,
  //     });
  //   });
  // };
  //
  const markNotificationsRead = () => {
    setLoading(true);
    markAllRead(api)
      .then(() => {
        getAllMessages(api)
          .then((data: NotificationPayload) => {
            setUnread(0);
            console.log(data);
            setItems(data);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setError(error);
          });
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  useEffect(() => {
    getAllMessages(api)
      .then((data: NotificationPayload) => {
        console.log(data);
        setItems(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }, [api]);

  return (
    <DefaultWrapper
      configLoaded={configLoaded}
      org_slug={org_slug}
      page="notifications"
      org_logo={org_logo}
      primary_color={primary_color}
      secondary_color={secondary_color}
      first_name={first_name}
      markAllRead={markNotificationsRead}
    >
      {loading && <Loading />}
      {totalItems() === 0 && (
        <div>
          <span>You have no notifications!</span>
        </div>
      )}
      {error && (
        <div>
          <span>Could not load notifications</span>
        </div>
      )}
      <div className="overflow-y-auto grow pb-24">
        <NotificationsBlock
          org_slug={org_slug}
          items={items.unread}
          title="Unread"
          unread={true}
        />
        <NotificationsBlock
          org_slug={org_slug}
          items={items.today}
          title="Today"
        />
        <NotificationsBlock
          org_slug={org_slug}
          items={items.yesterday}
          title="Yesterday"
        />
        <NotificationsBlock
          org_slug={org_slug}
          items={items.last_7_days}
          title="Last Week"
        />
        <NotificationsBlock
          org_slug={org_slug}
          items={items.past_month}
          title="Past Month"
        />
        <NotificationsBlock
          org_slug={org_slug}
          items={items.older}
          title="Older"
        />
      </div>
      <Navbar currentPage="notifications" org_slug={org_slug} />
    </DefaultWrapper>
  );
};

export default Notifications;
