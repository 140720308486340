import React, { useContext, createContext } from "react";

const OrgContext = createContext({
  first_name: "" as string | null,
  setFirstName: (_: string) => {},
  last_name: "" as string | null,
  setLastName: (_: string) => {},
  org_slug: "" as string | null,
  setOrgSlug: (_: string) => {},
  org_logo: "" as string | null,
  setOrgLogo: (_: string) => {},
  bot_name: "" as string | null,
  setBotName: (_: string) => {},
  bot_icon: "" as string | null,
  setBotIcon: (_: string) => {},
  bot_typing_msg: "" as string | null,
  setBotTypingMsg: (_: string) => {},
  bot_greeting_msg: "" as string | null,
  setBotGreetingMsg: (_: string) => {},
  chatTitle: "" as string | null,
  setChatTitle: (_: string) => {},
  pageTitle: "" as string | null,
  setPageTitle: (_: string) => {},
  primary_color: "" as string | null,
  setPrimaryColor: (_: string) => {},
  secondary_color: "" as string | null,
  setSecondaryColor: (_: string) => {},
  phone: "" as string | null,
  setPhone: (_: string) => {},
  configLoadedFromApi: false as boolean,
  setConfigLoadedFromApi: (_: boolean) => {},
  has_today_items: false as boolean,
  setHasTodayItems: (_: boolean) => {},
  query_type: "" as string | null,
  setQueryType: (_: string) => {},
});

export default OrgContext;

export const useOrgContext = () => useContext(OrgContext);

export const OrgProvider = ({ children }: { children: React.ReactNode }) => {
  const [org_slug, setOrgSlug] = React.useState<string | null>(null);
  const [org_logo, setOrgLogo] = React.useState<string | null>(null);
  const [bot_name, setBotName] = React.useState<string | null>(null);
  const [first_name, setFirstName] = React.useState<string | null>(null);
  const [last_name, setLastName] = React.useState<string | null>(null);
  const [bot_icon, setBotIcon] = React.useState<string | null>(null);
  const [chatTitle, setChatTitle] = React.useState<string | null>(null);
  const [pageTitle, setPageTitle] = React.useState<string | null>(null);
  const [phone, setPhone] = React.useState<string | null>(null);
  const [bot_typing_msg, setBotTypingMsg] = React.useState<string | null>(null);
  const [bot_greeting_msg, setBotGreetingMsg] = React.useState<string | null>(
    null
  );
  const [primary_color, setPrimaryColor] = React.useState<string | null>(null);
  const [secondary_color, setSecondaryColor] = React.useState<string | null>(
    null
  );
  const [has_today_items, setHasTodayItems] = React.useState<boolean>(false);
  const [query_type, setQueryType] = React.useState<string | null>(null);
  const [configLoadedFromApi, setConfigLoadedFromApi] =
    React.useState<boolean>(false);

  return (
    <OrgContext.Provider
      value={{
        org_slug,
        setOrgSlug,
        org_logo,
        setOrgLogo,
        bot_name,
        setBotName,
        first_name,
        setFirstName,
        last_name,
        setLastName,
        bot_icon,
        setBotIcon,
        chatTitle,
        setChatTitle,
        pageTitle,
        setPageTitle,
        phone,
        setPhone,
        bot_typing_msg,
        setBotTypingMsg,
        bot_greeting_msg,
        setBotGreetingMsg,
        primary_color,
        setPrimaryColor,
        secondary_color,
        setSecondaryColor,
        configLoadedFromApi,
        setConfigLoadedFromApi,
        has_today_items,
        setHasTodayItems,
        query_type,
        setQueryType,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};
