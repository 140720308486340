export const formatMessage = (text: string) => {
  // Replace double asterisks (**) with <b> tags for bold text
  let formattedText = text.replace(
    /\*\*(.*?)\*\*/g,
    '<span class="font-bold">$1</span>',
  );

  // Replace single asterisk (*) with <i> tags for italic text
  formattedText = formattedText.replace(
    /\*(?![*\s])(?:[^*]*[^*\s])?\*/g,
    '<span class="italic">$1</span>',
  );

  // Replace triple asterisks (***) with <b><i> tags for bold italic text
  formattedText = formattedText.replace(
    /\*\*\*(.*?)\*\*\*/g,
    '<span class="font-bold italic">$1</span>',
  );

  // Replace double underscore (__) with <u> tags for underlined text
  formattedText = formattedText.replace(
    /__(.*?)__/g,
    '<span class="underline">$1</span>',
  );

  // Replace single backtick (`) with <code> tags for inline code
  formattedText = formattedText.replace(/`(.*?)`/g, "<code>$1</code>");

  // Replace link markers with <a> tags for links
  formattedText = formattedText.replace(
    /\[([^\]]*)\]\((.*?)\)/gm,
    '<a href="$1" target="_blank" class="text-sky-500 underline text-wrap truncate"><span>$1</span></a>',
  );

  // Convert new lines into page breaks
  formattedText = formattedText.replace(/\n/g, "<br />");

  // Replace # to <span> tags for headings
  formattedText = formattedText.replace(
    /#(.*?)/g,
    '<span class="font-bold text-2xl">$1</span>',
  );
  formattedText = formattedText.replace(
    /##(.*?)/g,
    '<span class="font-bold text-xl">$1</span>',
  );
  formattedText = formattedText.replace(
    /###(.*?)/g,
    '<span class="font-bold text-lg">$1</span>',
  );

  return formattedText;
};
