import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Chat.css";
import { createSSERagQuery, fetchDocumentUrl } from "src/api/chat";
import { useNavigate, useParams } from "react-router";
import { useApi } from "src/api/api";
import { BASE_URL, OPENAI_REALTIME_RELAY_ENDPOINT } from "src/config";
import { formatMessage } from "src/helpers/format-message";
import { logout } from "src/helpers/logout";
import { useOrgContext } from "src/contexts/org-context";
import { getSelf } from "src/api/auth";
import useDocumentTitle from "src/helpers/useDocumentTitle";
// import Sidebar from './Sidebar';
import { mapIcon } from "src/helpers/map-icon";
import { useConfigFromApi } from "src/helpers/load-org-from-api";
import DefaultWrapper from "src/components/DefaultWrapper";
import { useLocation } from "react-router-dom";
import { useSubOrgContext } from "src/contexts/suborg-items-context";
import { WavRecorder } from "src/lib/wavtools/wav_recorder";
import { WavStreamPlayer } from "src/lib/wavtools/wav_stream_player";
import { RealtimeClient } from "@openai/realtime-api-beta";
import { useViewportHeight } from "src/helpers/viewportHeight";
import { getSessionToken } from "src/api/auth";
import Loader from "src/components/Loader";
import { fetchOrgConfig } from "src/api/org";
import { HighlightMenu, MenuButton } from "react-highlight-menu";

enum MessageSender {
  Bot = "bot",
  User = "user",
}

interface Message {
  id: number;
  message: string;
  sender: MessageSender;
  isProcessing?: boolean;
  docs: string[];
  images?: string[];
  imagesLoading: boolean;
}

const Chat = () => {
  const messageInputRef = React.useRef<HTMLDivElement>(null);
  const { org_slug = "", sub_org_slug = "" } = useParams();
  const [currentMessage, setCurrentMessage] = useState("" as string);
  const api = useApi(org_slug);
  const latestMessageRef = React.useRef<HTMLDivElement>(null);
  const {
    org_logo,
    first_name,
    setFirstName,
    setLastName,
    pageTitle,
    bot_greeting_msg,
    bot_typing_msg,
    primary_color,
    secondary_color,
    query_type,
  } = useOrgContext();
  const { configLoaded } = useConfigFromApi(org_slug);
  useDocumentTitle(pageTitle || "TeamMate");
  useViewportHeight();
  // const [showSidebar, setShowSidebar] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  // const [suborgPermitted, setSuborgPermitted] = useState(false);
  const messagesListRef = useRef<HTMLDivElement>(null);
  const amplitudeRef1 = useRef<SVGPathElement>(null);
  const amplitudeRef2 = useRef<SVGPathElement>(null);
  const scrollIntervalRef = useRef<number | null>(null);
  const { setCurrentSuborgTitle, cards } = useSubOrgContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [sendInitMessage, setSendInitMessage] = useState(false);
  const [enlargeImage, setEnlargeImage] = useState<string | boolean>(false);
  const [audioInputDialog, setAudioInputDialog] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [openaiInit, setOpenaiInit] = useState(false);
  const [muted, setMuted] = useState(false);
  const [voiceLoading, setVoiceLoading] = useState(false);
  const [queryType, setQueryType] = useState(query_type);
  const [showSearchDropdown, setShowSearchDropdown] = useState<null | string>(
    null,
  );
  const [suborgConfigLoaded, setSuborgConfigLoaded] = useState(
    !(sub_org_slug && sub_org_slug.length > 0),
  );

  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({
      sampleRate: 24000,
    }),
  );

  const wavPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({
      sampleRate: 24000,
    }),
  );

  const openaiClientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      url: `${OPENAI_REALTIME_RELAY_ENDPOINT}/${
        sub_org_slug && sub_org_slug.length > 0 ? sub_org_slug : org_slug
      }`,
      dangerouslyAllowAPIKeyInBrowser: true,
      apiKey: "",
    }),
  );

  // Create realtime client
  useEffect(() => {
    // Initialize the realtime client ref
    getSessionToken(org_slug).then((token) => {
      openaiClientRef.current = new RealtimeClient({
        url: `${OPENAI_REALTIME_RELAY_ENDPOINT}/${
          sub_org_slug && sub_org_slug.length > 0 ? sub_org_slug : org_slug
        }`,
        dangerouslyAllowAPIKeyInBrowser: true,
        apiKey: token,
      });
      setOpenaiInit(true);
    });
  }, [org_slug, sub_org_slug]);

  const getAmplitude = () => {
    const wavRecorder = wavRecorderRef.current;

    const result = wavRecorder.recording
      ? wavRecorder.getFrequencies("voice")
      : { values: new Float32Array([0]) };

    const frequencyData = result.values;

    const length = frequencyData.length;
    const midPoint = Math.floor(length / 2);

    // Low frequencies
    const lowFreqs = frequencyData.slice(0, midPoint);
    const lowAmplitude = Math.sqrt(
      lowFreqs.reduce((sum, value) => sum + value ** 2, 0) / midPoint,
    );

    // High frequencies
    const highFreqs = frequencyData.slice(midPoint);
    const highAmplitude = Math.sqrt(
      highFreqs.reduce((sum, value) => sum + value ** 2, 0) /
        (length - midPoint),
    );

    const scaleLow = 1 + lowAmplitude * 0.5;
    const scaleHigh = 1 + highAmplitude * 0.5;

    return [scaleLow, scaleHigh];
  };

  const dynamicRotatingAmplitudeBlob = (
    ref: React.RefObject<SVGPathElement>,
    index: number,
  ) => {
    let angle = 0;
    let direction = Math.random() > 0.5 ? 1 : -1;
    let speed = Math.random() * 0.5 + 0.5;
    let targetSpeed = speed;
    const minSpeed = 0.01;
    const maxSpeed = 1;
    const directionChangeInterval = 2000 + Math.random() * 3000;
    let speedChangeInterval = 500 + Math.random() * 200;
    let lastDirectionChangeTime = Date.now();
    let lastChangeTime = Date.now();
    const pauseProbability = 0.1;
    const pauseDuration = 1000 + Math.random() * 2000;
    let isPaused = false;
    let pauseEndTime = 0;

    function rotate() {
      const now = Date.now();

      if (isPaused) {
        if (now >= pauseEndTime) {
          isPaused = false;
        } else {
          targetSpeed = 0;
        }
      }

      speed += (targetSpeed - speed) * 0.05;

      angle += speed * direction;

      if (ref.current) {
        const amplitude = getAmplitude()[index];
        ref.current.style.transform = `rotate(${angle}deg) scale(${amplitude})`;
        ref.current.style.transformOrigin = "center center";
      }

      if (now - lastDirectionChangeTime > directionChangeInterval) {
        lastDirectionChangeTime = now;
        direction = Math.random() > 0.5 ? 1 : -1;
      }
      if (now - lastChangeTime > speedChangeInterval && !isPaused) {
        lastChangeTime = now;
        targetSpeed = Math.random() * (maxSpeed - minSpeed) + minSpeed;
        speedChangeInterval = 500 + Math.random() * 200;

        // Occasionally, pause the rotation
        if (Math.random() < pauseProbability) {
          isPaused = true;
          pauseEndTime = now + pauseDuration;
        }
      }

      window.requestAnimationFrame(rotate);
    }

    rotate();
  };

  const beginAudio = async () => {
    setAudioInputDialog(true);
    if (sessionStarted) {
      return;
    }

    setVoiceLoading(true);

    setSessionStarted(true);

    const client = openaiClientRef.current;
    const wavRecorder = wavRecorderRef.current;

    await wavRecorder.begin();

    await wavPlayerRef.current.connect();

    await client.connect();

    await wavRecorder.record((data) => {
      client.appendInputAudio(data.mono);
    });

    dynamicRotatingAmplitudeBlob(amplitudeRef1, 0);
    dynamicRotatingAmplitudeBlob(amplitudeRef2, 1);

    setVoiceLoading(false);
  };

  const cancelAudio = async () => {
    setVoiceLoading(true);
    setAudioInputDialog(false);
    setSessionStarted(false);

    const client = openaiClientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavPlayer = wavPlayerRef.current;

    await wavRecorder.quit();
    client.disconnect();
    wavPlayer.interrupt();
    console.log("Audio recording cancelled");

    // Delete any message where isProcessing is true
    setMessages((messages) =>
      messages.filter((message) => !message.isProcessing),
    );

    setVoiceLoading(false);
  };

  const toggleMute = async () => {
    const wavPlayer = wavPlayerRef.current;

    if (wavPlayer.isMuted()) {
      wavPlayer.unmute();
    } else {
      wavPlayer.mute();
    }

    setMuted(wavPlayer.isMuted());
  };

  // Continue to automatically scroll to the bottom of the chat when new messages are added, and cancel the scroll when the user scrolls up
  const beginAutoscroll = useCallback(() => {
    if (messagesListRef.current) {
      messagesListRef.current.scrollTo(0, messagesListRef.current.scrollHeight);
    }

    // Check if the scroll interval is already running
    if (scrollIntervalRef.current) {
      return;
    }

    // Every 100ms, check if the the page needs to be scrolled to the bottom
    scrollIntervalRef.current = window.setInterval(() => {
      if (messagesListRef.current) {
        messagesListRef.current.scrollTo(
          0,
          messagesListRef.current.scrollHeight,
        );
      }
    }, 100);

    // Check if the user has scrolled up
    messagesListRef.current?.addEventListener("scroll", () => {
      if (messagesListRef.current) {
        if (
          messagesListRef.current.scrollTop <
          messagesListRef.current.scrollHeight -
            messagesListRef.current.clientHeight
        ) {
          // Stop the autoscroll
          clearInterval(scrollIntervalRef.current!);
          scrollIntervalRef.current = null;
        }

        // If the user has scrolled to the bottom, resume autoscroll
        if (
          messagesListRef.current.scrollTop ===
          messagesListRef.current.scrollHeight -
            messagesListRef.current.clientHeight
        ) {
          beginAutoscroll();
        }
      }
    });
  }, []);

  const sendMessage = useCallback(
    async (message: string) => {
      if (!message || message.trim() === "") {
        return;
      }

      console.log("Current message", currentMessage);
      console.log("Sending message:", message);
      console.log("Sub org slug: ", sub_org_slug);

      // Create an SSE rag query
      const response = await createSSERagQuery(
        api,
        sub_org_slug.length > 0 ? sub_org_slug : org_slug,
        message,
      );
      console.log("Response:", response);

      // Add the message to the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          message: message,
          sender: MessageSender.User,
          docs: [],
          imagesLoading: false,
        },
        {
          id: prevMessages.length + 2,
          message: "",
          sender: MessageSender.Bot,
          isProcessing: true,
          docs: [],
          images: [],
          imagesLoading: false,
        },
      ]);

      // Automatically scroll to the bottom of the chat
      beginAutoscroll();

      // Create event source
      const eventSource = new EventSource(`${BASE_URL}${response.stream_url}`);

      let incomingMessage = "";
      let docs: string[] = [];
      let images: string[] = [];
      let isProcessing = true;

      // Add event listener
      eventSource.addEventListener("message", (event) => {
        // Scroll to the bottom of the chat
        window.scrollTo(0, document.body.scrollHeight);

        if (event.data === "[DONE]") {
          console.log("Closing event source");

          // Update the current message
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage.sender === "bot") {
              lastMessage.message = incomingMessage;
              lastMessage.docs = docs;
              lastMessage.images = images;
              if (images.length > 0) {
                lastMessage.imagesLoading = false;
              }
            }

            return [...prevMessages];
          });

          eventSource.close();
        } else if (event.data.startsWith("[IMAGES_LOADING]")) {
          // Update message property
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage.sender === "bot") {
              lastMessage.imagesLoading = true;
            }

            return [...prevMessages];
          });
        } else if (event.data.startsWith("[CANCEL_IMAGES]")) {
          // Update message property
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage.sender === "bot") {
              lastMessage.imagesLoading = false;
            }

            return [...prevMessages];
          });
        } else if (event.data.startsWith("[IMAGES]")) {
          const imageString = event.data.replace("[IMAGES]", "");

          // Split the images by the starting @@@ in each image title
          images = imageString
            .split("@@@@")
            .filter((image: string) => image.trim() !== "");
        } else if (event.data.startsWith("[DOCS]")) {
          // Remove the [DOCS] prefix
          const docString = event.data.replace("[DOCS]", "");

          // Split the docs by the starting @@@ in each document title
          docs = docString
            .split("@@@@")
            .filter((doc: string) => doc.trim() !== "");
        } else if (event.data.startsWith("[ERROR]")) {
          console.error("Error:", event.data);
          eventSource.close();
        } else {
          // Make sure we switch to message activated mode
          if (isProcessing) {
            isProcessing = false;
            setMessages((prevMessages) => {
              const lastMessage = prevMessages[prevMessages.length - 1];
              if (lastMessage.sender === "bot") {
                lastMessage.isProcessing = false;
              }

              return [...prevMessages];
            });
          }

          // Update the incoming message
          incomingMessage += formatMessage(event.data);

          // Make sure the incoming message is also formatted
          incomingMessage = formatMessage(incomingMessage);

          // Update the current message
          if (latestMessageRef.current) {
            latestMessageRef.current!.innerHTML = incomingMessage;
          }
        }
      });

      // Add event listener
      eventSource.addEventListener("error", (event) => {
        console.error("Error:", event);
        eventSource.close();
      });
    },
    [api, currentMessage, org_slug, sub_org_slug, beginAutoscroll],
  );

  const handleMessage = useCallback(() => {
    const messageInput = messageInputRef.current;

    if (messageInput) {
      const text = messageInput.outerText;

      // Add the message to the chat
      sendMessage(text);

      // Clear the input
      messageInput.innerHTML = "";
    }
  }, [sendMessage]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMessage(event.target.innerText);
  };

  const handleKeyDown = useRef((event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleMessage();
    }
  });

  useEffect(() => {
    // Bind event handlers to input
    const messageInput = messageInputRef.current;
    if (messageInput) {
      messageInput.addEventListener("keydown", handleKeyDown.current);
    }
  }, []);

  useEffect(() => {
    if (first_name && first_name.length > 0) {
      return;
    }
    // Load uesr info
    getSelf(api, org_slug)
      .then((self) => {
        setFirstName(self.first_name);
        setLastName(self.last_name);
      })
      .catch((e) => {
        console.error("Error getting self:", e);
      });
  }, [api, org_slug, setFirstName, setLastName, first_name]);

  useEffect(() => {
    if (configLoaded && messages.length === 0) {
      // Append welcome message
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          message: bot_greeting_msg || "Hello, how can I help you today?",
          sender: MessageSender.Bot,
          docs: [],
          imagesLoading: false,
        },
      ]);
    }
  }, [configLoaded, bot_greeting_msg, messages.length]);

  useEffect(() => {
    if (sub_org_slug) {
      api.get(`/org/${org_slug}/permitted/${sub_org_slug}`).then((data) => {
        console.log("permission", data);
        if (
          data &&
          data.data &&
          data.data.today_item &&
          data.data.today_item.title
        ) {
          setCurrentSuborgTitle(data.data.today_item.title);
        }
      });
    } else {
      setCurrentSuborgTitle("");
    }
  }, [org_slug, sub_org_slug, api, setCurrentSuborgTitle]);

  useEffect(() => {
    if (sendInitMessage) return;
    if (state && state.message && state.message.length > 0) {
      // Set the current message
      messageInputRef.current!.innerHTML = state.message;

      setSendInitMessage(true);

      // Send the message
      handleMessage();
    }
  }, [state, handleMessage, sendInitMessage, setSendInitMessage]);

  // RT OpenAI config
  useEffect(() => {
    if (!openaiInit) return;

    const openai = openaiClientRef.current;

    const wavPlayer = wavPlayerRef.current;

    // Set instructions
    // openai.updateSession({ instructions: instructions });
    // // Set transcription, otherwise we don't get user transcriptions back
    // openai.updateSession({ input_audio_transcription: { model: "whisper-1" } });

    openai.on("realtime.event", (data: any) => {
      // console.log(data.event);
      if (
        data.event.type !== "input_audio_buffer.append" &&
        data.event.type !== "response.audio.delta"
      ) {
        // console.log("realtime event", data.event);
      }
      if (data.event.type === "response.created") {
        console.log("conversation event", data.event);

        // If there is already a message being processed, we need to update it
        setMessages((prevMessages) => {
          const newMessage = {
            id: prevMessages.length + 1,
            message: data.event.text,
            sender: MessageSender.Bot,
            docs: [],
            imagesLoading: false,
            isProcessing: true,
          };

          // Remove the in progress messages
          const filteredMessages = prevMessages.filter((m) => !m.isProcessing);

          return [...filteredMessages, newMessage];
        });
      }
      if (
        data.event.type ===
        "conversation.item.input_audio_transcription.completed"
      ) {
        console.log("transcription event", data.event);
        setMessages((prevMessages) => {
          // Insert a message before the bot message with the transcription
          const before = prevMessages.slice(0, prevMessages.length - 1);
          const after = prevMessages[prevMessages.length - 1];

          const newMessage = {
            id: prevMessages.length + 1,
            message: data.event.transcript,
            sender: MessageSender.User,
            docs: [],
            imagesLoading: false,
          };

          return [...before, newMessage, after];
        });
      }
    });
    openai.on("error", (data: any) => {
      console.error(data.event.error);
    });
    openai.on("conversation.interrupted", async (data: any) => {
      // TODO: Handle audio playback context
      const trackSampleOffset = await wavPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        await openai.cancelResponse(
          trackSampleOffset.trackId,
          trackSampleOffset.offset,
        );
      }
    });
  }, [openaiInit]);

  // Setup message listener
  useEffect(() => {
    const openai = openaiClientRef.current;

    const wavPlayer = wavPlayerRef.current;

    const convoUpdater = ({ item, delta }: any) => {
      // const client = openaiClientRef.current;
      if (delta?.audio) {
        wavPlayer.add16BitPCM(delta.audio, item.id);
      }

      // const items = client.conversation.getItems();
      // const item = items[items.length - 1] as any;
      if (item.content && item.content.length > 0) {
        if (
          item.content[0].type &&
          item.content[0].type === "audio" &&
          item.content[0].transcript
        ) {
          const transcript = item.content[0].transcript;

          // Check if the latest message is processing, if not add a new message and set its content
          if (messages.length > 0) {
            if (messages[messages.length - 1].sender === MessageSender.Bot) {
              setMessages((prevMessages) => {
                if (prevMessages[prevMessages.length - 1].isProcessing) {
                  prevMessages[prevMessages.length - 1].isProcessing = false;
                }
                prevMessages[prevMessages.length - 1].message =
                  formatMessage(transcript);
                return [...prevMessages];
              });
            } else {
              console.log("adding new message");
              setMessages((prevMesages) => {
                return [
                  ...prevMesages,
                  {
                    isProcessing: false,
                    message: formatMessage(transcript),
                    sender: MessageSender.Bot,
                    docs: [],
                    imagesLoading: false,
                    id: prevMesages.length + 1,
                  },
                ];
              });
            }
          } else {
            setMessages([
              {
                isProcessing: true,
                message: formatMessage(transcript),
                sender: MessageSender.Bot,
                docs: [],
                imagesLoading: false,
                id: 0,
              },
            ]);
          }
        }
      }
    };

    openai.on("conversation.updated", convoUpdater);

    return () => {
      openai.off("conversation.updated", convoUpdater);
    };
  }, [messages, setMessages]);

  useEffect(() => {
    // If a suborg slug is provided, fetch the suborg config, to determine the query type
    if (sub_org_slug && sub_org_slug.length > 0) {
      fetchOrgConfig(sub_org_slug, false)
        .then((data) => {
          if (data?.config) {
            setQueryType(data.config.query_type);
          }
          setSuborgConfigLoaded(true);
        })
        .catch((error) => {
          console.error("Error fetching suborg config:", error);
          setSuborgConfigLoaded(true);
        });
    }
  }, [sub_org_slug]);

  // const overwriteQuery = (query: string) => {
  //     const messageInput = messageInputRef.current;
  //     if (messageInput) {
  //         messageInput.innerHTML = query;
  //     }
  //     setShowSidebar(false);
  // }

  const openDocument = async (doc: string) => {
    try {
      const result = await fetchDocumentUrl(
        api,
        sub_org_slug && sub_org_slug.length > 0 ? sub_org_slug : org_slug,
        doc,
      );

      if (result.url) {
        window.open(result.url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  // const stopRecording = async () => {
  //   setAudioRecording(false);
  //   setAudioInputDialog(false);

  //   setMessages((prevMessages) => {
  //     return [
  //       ...prevMessages,
  //       {
  //         isProcessing: true,
  //         id: prevMessages.length + 1,
  //         docs: [],
  //         message: "",
  //         sender: MessageSender.Bot,
  //         imagesLoading: false,
  //       },
  //     ];
  //   });

  //   const wavRecorder = wavRecorderRef.current;
  //   const openai = openaiClientRef.current;

  //   if (wavRecorder.recording) {
  //     await wavRecorder.pause();

  //     if (openai.inputAudioBuffer.byteLength > 0) {
  //       // Commit audio buffer and queue conversation
  //       openai.realtime.send("input_audio_buffer.commit", {});
  //       openai.conversation.queueInputAudio(openai.inputAudioBuffer);

  //       // Clear buffer to prepare for next conversation
  //       openai.inputAudioBuffer = new Int16Array(0);
  //     }
  //   }
  // };
  //

  const determineSendStyle = (message_length: number, qt: string) => {
    if (message_length > 1) {
      return "bg-primary shadow-md";
    }

    if (qt === "VEC") {
      return "bg-none";
    } else {
      return "bg-[#D0DADB] shadow-md";
    }
  };

  return (
    <DefaultWrapper
      configLoaded={configLoaded && suborgConfigLoaded}
      primary_color={primary_color}
      secondary_color={secondary_color}
      org_slug={org_slug}
      org_logo={org_logo}
      logout={logout}
      page="chat"
    >
      <div className="w-full h-[var(--vh)] max-w-2xl flex flex-col ml-auto mr-auto relative overflow-hidden">
        <div
          className="flex-1 px-3 overflow-y-auto overflow-x-hidden max-h-screen"
          ref={messagesListRef}
        >
          <HighlightMenu
            target={messagesListRef}
            allowedPlacements={["top", "bottom"]}
            menu={({ selectedText = "", setClipboard, setMenuOpen }) => (
              <>
                <MenuButton
                  title="Copy to clipboard"
                  icon="clipboard"
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  onClick={() => {
                    setClipboard(selectedText);
                    setMenuOpen(false);
                  }}
                />
                <MenuButton
                  title="Search in another document set"
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  onClick={() => {
                    setShowSearchDropdown(selectedText);
                    setMenuOpen(false);
                  }}
                  icon="magnifying-glass"
                />
              </>
            )}
          />
          {messages.map((message, index) => (
            <div
              key={message.id}
              className={`p-1 flex ${
                message.sender === MessageSender.User && "justify-end"
              }`}
            >
              {message.isProcessing && (
                <div className="m-2 rounded-xl shadow-sm bg-white max-w-content w-max fade-in">
                  <div className="p-2 flex flex-row max-w-content">
                    <p className="text-blackinline-block text-left shrink">
                      <span>{bot_typing_msg || "Bot is thinking"}</span>
                      <div className="blink-dot"></div>
                      <div className="blink-dot"></div>
                      <div className="blink-dot"></div>
                    </p>
                  </div>
                </div>
              )}
              {!message.isProcessing && (
                <div
                  className={`m-1 rounded-xl shadow-sm w-max text-base ${
                    message.sender === MessageSender.Bot
                      ? "bg-white fly-in-right rounded-bl-none mr-5"
                      : "bg-primary fly-in-left rounded-br-none text-white ml-5"
                  }`}
                >
                  <div
                    className={`p-2 flex flex-row w-max-80 ${
                      message.sender === MessageSender.Bot
                        ? "mr-auto"
                        : "ml-auto"
                    }`}
                  >
                    <div className="flex flex-col">
                      {index === messages.length - 1 &&
                      message.sender === MessageSender.Bot ? (
                        <div
                          ref={latestMessageRef}
                          className={
                            "inline-block text-left shrink px-2 break-words overflow-hidden"
                          }
                          dangerouslySetInnerHTML={{ __html: message.message }}
                        ></div>
                      ) : (
                        <div
                          className={`inline-block ${
                            message.sender === MessageSender.Bot
                              ? "text-left"
                              : "text-right"
                          } shrink grow px-2 break-words overflow-hidden`}
                          dangerouslySetInnerHTML={{ __html: message.message }}
                        ></div>
                      )}
                      {message.imagesLoading && (
                        <div className="flex justify-center mt-2">
                          <svg
                            aria-hidden="true"
                            className="w-7 h-7 text-gray-200 animate-spin fill-primary"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="none"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      {message.images && message.images.length > 0 && (
                        <div className="flex">
                          {message.images.map((image: any, index: any) => (
                            <div
                              key={index}
                              onClick={() =>
                                setEnlargeImage(
                                  `https://sopai-images-dev.s3.us-east-2.amazonaws.com/${image.trim()}`,
                                )
                              }
                              className="cursor-pointer mt-2 hover:brightness-50 transition duration-75"
                            >
                              <img
                                src={`https://sopai-images-dev.s3.us-east-2.amazonaws.com/${image.trim()}`}
                                alt={image}
                                width={200}
                                height={200}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      {message.docs.length > 0 && (
                        <div className="flex flex-row w-full pl-2 mt-5 pr-5">
                          <div className="grid grid-cols-2 gap-2 w-full">
                            {message.docs.map((doc, index) => (
                              <button
                                key={index}
                                className="text-black text-left w-full pl-2 mr-5 bg-[#E8E5DA] rounded-lg p-1 h-14 flex items-center shadow-sm hover:shadow-lg"
                                onClick={() => openDocument(doc)}
                              >
                                <img
                                  src={mapIcon(
                                    doc.split(".").pop()?.toLocaleLowerCase() ||
                                      "",
                                  )}
                                  alt="doc"
                                  width={40}
                                  height={40}
                                  className="mr-2"
                                />
                                <span className="truncate text-sm">{doc}</span>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {showSearchDropdown && showSearchDropdown.length > 0 && (
          <div className="bg-black/50 absolute top-0 left-0 w-full h-full z-30">
            <div className="bg-white m-5 rounded-xl shadow-lg ">
              <h1 className="font-bold">Select a document set to search in</h1>
              <ul className="divide-solid divide-y">
                {cards.map((suborg) => (
                  <li key={suborg.suborg}>
                    <button
                      className="w-full p-3 hover:bg-gray-200 transition duration-75 rounded-xl"
                      onClick={() => {
                        setShowSearchDropdown(null);
                        navigate(`/${org_slug}/chat/${suborg.suborg}`, {
                          state: {
                            message: showSearchDropdown,
                          },
                        });
                      }}
                    >
                      <p className="text-left text-lg font-bold">
                        {suborg.title}
                      </p>
                      <p className="text-left text-gray-400">
                        {suborg.description}
                      </p>
                    </button>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => setShowSearchDropdown(null)}
                className="bg-red-500 text-white p-2 rounded-xl w-full mt-2 hover:bg-red-600 transition duration-75"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {enlargeImage && (
          <div
            className="w-full h-full absolute top-0 left-0 flex items-center justify-center backdrop-brightness-50"
            onClick={() => setEnlargeImage(false)}
          >
            {typeof enlargeImage == "string" && (
              <img src={enlargeImage} alt="enlarge" className="m-auto" />
            )}
          </div>
        )}
        {audioInputDialog && voiceLoading && (
          <div className="flex justify-center items-center p-8 w-full">
            <Loader />
          </div>
        )}
        {audioInputDialog && !voiceLoading && (
          <div className=" flex items-center justify-center bg-gradient-to-t to-50% from-slate-900 via-none to-none p-12 flex-col">
            <div className={`z-10 rounded-full drop-shadow-2xl relative`}>
              <button onClick={() => cancelAudio()}>
                <svg
                  width="155"
                  height="154"
                  viewBox="0 0 105 104"
                  fill="none"
                  version="1.1"
                  id="svg5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="m 62.988655,18.164429 c 9.2643,2.9358 20.433,5.83428 23.9175,14.63529 3.4711,8.767 -5.1489,16.5407 -8.1367,25.2232 -3.5316,10.263 -0.1489,25.7492 -10.6745,29.4421 -10.5258,3.6929 -19.4622,-9.4528 -29.2048,-15.4505 -7.5386,-4.641 -17.32454,-7.1431 -20.51328,-15.1574 -3.16816,-7.9625 1.17018,-16.2188 5.10139,-23.5776 3.59939,-6.7377 8.48879,-12.7677 15.81139,-15.56903 7.526,-2.879166 15.8068,-2.047012 23.699,0.45394 z"
                    fill="#73d9e8"
                    id="path1"
                    ref={amplitudeRef1}
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="m 34.612327,31.735727 c 8.3422,-5.31398 17.9412,-12.027078 26.5802,-10.34013 8.6056,1.68042 8.3523,11.65533 12.5445,17.98003 4.9553,7.4761 18.304,12.4381 13.8497,21.841 -4.4545,9.4032 -19.8732,9.5902 -30.7263,13.7848 -8.3979,3.2457 -16.7816,9.1456 -24.659,7.6209 -7.8264,-1.5149 -10.80374,-8.6152 -13.41401,-14.9904 -2.38997,-5.8372 -3.40464,-12.2711 -0.47177,-18.9239 3.01437,-6.8376 9.19008,-12.4454 16.29668,-16.9723 z"
                    fill="#42aebe"
                    id="path2"
                    ref={amplitudeRef2}
                  />
                  <g
                    filter="url(#filter0_d_297_3372)"
                    id="g3"
                    transform="translate(13.5,15)"
                  >
                    <rect
                      x="7"
                      y="3"
                      width="64"
                      height="64"
                      rx="32"
                      fill="#077e8f"
                      id="rect2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="m 33.25,31 c 0,-3.1756 2.5744,-5.75 5.75,-5.75 3.1756,0 5.75,2.5744 5.75,5.75 v 3 c 0,3.1756 -2.5744,5.75 -5.75,5.75 -3.1756,0 -5.75,-2.5744 -5.75,-5.75 z M 39,26.75 c -2.3472,0 -4.25,1.9028 -4.25,4.25 v 3 c 0,2.3472 1.9028,4.25 4.25,4.25 2.3472,0 4.25,-1.9028 4.25,-4.25 v -3 c 0,-2.3472 -1.9028,-4.25 -4.25,-4.25 z M 37.25,31 c 0,-0.4142 0.3358,-0.75 0.75,-0.75 h 2 c 0.4142,0 0.75,0.3358 0.75,0.75 0,0.4142 -0.3358,0.75 -0.75,0.75 h -2 c -0.4142,0 -0.75,-0.3358 -0.75,-0.75 z M 31,32.25 c 0.4142,0 0.75,0.3358 0.75,0.75 v 1 c 0,4.0041 3.2459,7.25 7.25,7.25 4.0041,0 7.25,-3.2459 7.25,-7.25 v -1 c 0,-0.4142 0.3358,-0.75 0.75,-0.75 0.4142,0 0.75,0.3358 0.75,0.75 v 1 c 0,4.5798 -3.5186,8.3379 -8,8.7183 V 45 c 0,0.4142 -0.3358,0.75 -0.75,0.75 -0.4142,0 -0.75,-0.3358 -0.75,-0.75 v -2.2817 c -4.4814,-0.3804 -8,-4.1385 -8,-8.7183 v -1 c 0,-0.4142 0.3358,-0.75 0.75,-0.75 z M 36.25,34 c 0,-0.4142 0.3358,-0.75 0.75,-0.75 h 4 c 0.4142,0 0.75,0.3358 0.75,0.75 0,0.4142 -0.3358,0.75 -0.75,0.75 h -4 c -0.4142,0 -0.75,-0.3358 -0.75,-0.75z"
                      fill="#ffffff"
                      id="path3"
                    />
                  </g>
                  <defs id="defs5">
                    <filter
                      id="filter0_d_297_3372"
                      x="7"
                      y="3"
                      width="64"
                      height="68"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                        id="feFlood3"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                        id="feColorMatrix3"
                      />
                      <feMorphology
                        radius="4"
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow_297_3372"
                        id="feMorphology3"
                      />
                      <feOffset dy="4" id="feOffset3" />
                      <feGaussianBlur stdDeviation="2" id="feGaussianBlur3" />
                      <feComposite
                        in2="hardAlpha"
                        operator="out"
                        id="feComposite3"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0980392 0 0 0 0 0.0980392 0 0 0 0 0.0980392 0 0 0 0.08 0"
                        id="feColorMatrix4"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_297_3372"
                        id="feBlend4"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_297_3372"
                        result="shape"
                        id="feBlend5"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
            </div>
            <button onClick={toggleMute}>
              <div
                className={`${
                  muted ? "bg-red-600" : "bg-white"
                } p-2 rounded-full drop-shadow-lg`}
              >
                {muted ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                    />
                  </svg>
                )}
              </div>
            </button>
          </div>
        )}
        <div
          className={`bg-white min-h-14 p-2 flex shadow-xl border-solid border-t-2 border-[#E7EAEB] ${
            audioInputDialog && "invisible hidden"
          }`}
        >
          <div
            className="h-9 align-middle chat-input flex-1 bg-[#F1F4F5] rounded-lg border-solid border-2 border-[#E7EAEB]"
            data-placeholder="Reply to TeamMate"
            ref={messageInputRef}
            onInput={handleInputChange}
            contentEditable
          ></div>
          <button
            className={`rounded-full ${determineSendStyle(
              (messageInputRef.current &&
                messageInputRef.current.outerText &&
                messageInputRef.current.outerText.length) ||
                0,
              queryType || "",
            )} w-9 h-9 flex items-center justify-center mx-2`}
            onClick={
              (messageInputRef.current &&
                messageInputRef.current.outerText.length > 1) ||
              queryType !== "VEC"
                ? handleMessage
                : beginAudio
            }
          >
            {(messageInputRef.current &&
              messageInputRef.current.outerText.length > 1) ||
            queryType !== "VEC" ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14.5C7 15.0523 7.44772 15.5 8 15.5C8.55228 15.5 9 15.0523 9 14.5L7 14.5ZM8.70711 0.792893C8.31658 0.402369 7.68342 0.402369 7.29289 0.792893L0.928932 7.15685C0.538408 7.54738 0.538408 8.18054 0.928932 8.57107C1.31946 8.96159 1.95262 8.96159 2.34315 8.57107L8 2.91421L13.6569 8.57107C14.0474 8.96159 14.6805 8.96159 15.0711 8.57107C15.4616 8.18054 15.4616 7.54738 15.0711 7.15685L8.70711 0.792893ZM9 14.5L9 1.5L7 1.5L7 14.5L9 14.5Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="22.5"
                height="26.25"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.25 6C3.25 2.82436 5.82436 0.25 9 0.25C12.1756 0.25 14.75 2.82436 14.75 6V9C14.75 12.1756 12.1756 14.75 9 14.75C5.82436 14.75 3.25 12.1756 3.25 9V6ZM9 1.75C6.65279 1.75 4.75 3.65279 4.75 6V9C4.75 11.3472 6.65279 13.25 9 13.25C11.3472 13.25 13.25 11.3472 13.25 9V6C13.25 3.65279 11.3472 1.75 9 1.75ZM7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H10C10.4142 5.25 10.75 5.58579 10.75 6C10.75 6.41421 10.4142 6.75 10 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6ZM1 7.25C1.41421 7.25 1.75 7.58579 1.75 8V9C1.75 13.0041 4.99594 16.25 9 16.25C13.0041 16.25 16.25 13.0041 16.25 9V8C16.25 7.58579 16.5858 7.25 17 7.25C17.4142 7.25 17.75 7.58579 17.75 8V9C17.75 13.5798 14.2314 17.3379 9.75 17.7183V20C9.75 20.4142 9.41421 20.75 9 20.75C8.58579 20.75 8.25 20.4142 8.25 20V17.7183C3.7686 17.3379 0.25 13.5798 0.25 9V8C0.25 7.58579 0.585786 7.25 1 7.25ZM6.25 9C6.25 8.58579 6.58579 8.25 7 8.25H11C11.4142 8.25 11.75 8.58579 11.75 9C11.75 9.41421 11.4142 9.75 11 9.75H7C6.58579 9.75 6.25 9.41421 6.25 9Z"
                  fill="#031618"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    </DefaultWrapper>
  );
};

export default Chat;
