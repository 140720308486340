import YozoraParser from "@yozora/parser";
import Markdown, { MarkdownProvider } from "@yozora/react-markdown";
import { calcDefinitionMap } from "@yozora/ast-util";
import React from "react";
import { NotificationItem } from "src/screens/Messaging/Notifications";
import { useNavigate } from "react-router";
import truncateMarkdown from "markdown-truncate";

const NotificationsBlock = ({
  title,
  items,
  org_slug,
  unread = false,
}: {
  items: NotificationItem[];
  unread?: boolean;
  title: string;
  org_slug: string;
}) => {
  const parser = new YozoraParser();
  const navigate = useNavigate();

  if (items.length === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col">
      <span className="font-bold text-sm text-[#878D90] text-left px-5 py-3 pb-4">
        {title}
      </span>
      <div className="bg-white divide-y">
        {items.map((item) => {
          const ast = parser.parse(
            truncateMarkdown(item.post.content, {
              limit: 250,
              ellipsis: true,
            }),
            {}
          );
          const definitionMap = calcDefinitionMap(ast);

          return (
            <button
              className="w-full max-h-40 overflow-hidden"
              onClick={() => {
                navigate(`/${org_slug}/notification/${item.post.id}`);
              }}
            >
              <div className="text-left p-4 px-5 select-none">
                <div className="flex justify-between items-center">
                  <span className="font-bold text-lg truncate">
                    {item.post.title}
                  </span>
                  {unread && (
                    <div className="rounded-full bg-primary w-2 h-2"></div>
                  )}
                </div>
                <MarkdownProvider definitionMap={definitionMap as any}>
                  <Markdown ast={ast} />
                </MarkdownProvider>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationsBlock;
